import React from 'react';
import Retinax from './Retinax';
import './utils/script';
import { toggleMenu } from './utils/script';
import './utils/style.css';
import { Link, Element } from 'react-scroll';
import { Link as LinkDom } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  return (
    <Element name='header'>
      <header
        id="header"
        className="d-flex align-items-center mt-3"
      >
          <div 
            className='container d-flex align-items-center justify-content-between collapse navbar-collapse'
          >
            <div>
              <a className='no-decoration' href='/'><Retinax/></a>
            </div>
            <nav
              id="desktop-nav"
              className='navbar navbar-expand-lg navbar-light'
            >
              
              <div
                className='collapse navbar-collapse'
              >
                <ul
                  className='navbar-nav mr-auto'
                >
                  <li
                    className="nav-item active"
                  >
                    {/* <a href=''> */}
                      <Link 
                        to="header" 
                        smooth={true} 
                        duration={500}
                        className='nav-link'
                      >Home</Link>
                    {/* </a> */}
                  </li>
                  <li
                    className="nav-item active"
                  >
                    {/* <a href=''> */}
                      <Link 
                        to="about" 
                        smooth={true} 
                        duration={500}
                        className='nav-link'
                      >Sobre</Link>
                    {/* </a> */}
                  </li>
                  <li
                    className="nav-item active"
                  >
                    {/* <a href=''> */}
                      <Link 
                        to="services" 
                        smooth={true} 
                        duration={500}
                        className='nav-link'
                      >Serviços</Link>
                    {/* </a> */}
                  </li>
                  <li
                    className="nav-item active"
                  >
                    {/* <a href=''> */}
                      <Link 
                        to="team" 
                        smooth={true} 
                        duration={500}
                        className='nav-link'
                      >Time</Link>
                    {/* </a> */}
                  </li>
                  <li
                    className="nav-item active"
                  >
                    {/* <a href=''> */}
                      <Link 
                        to="pricing" 
                        smooth={true} 
                        duration={500}
                        className='nav-link'
                      >Assinatura</Link>
                    {/* </a> */}
                  </li>
                  <li
                    className="nav-item active"
                  >
                    {/* <a href=''> */}
                      <Link 
                        to="contact" 
                        smooth={true} 
                        duration={500}
                        className='nav-link'
                      >Contato</Link>
                    {/* </a> */}
                  </li>
                  <li
                    className="nav-item active"
                  >
                    {/* <a href=''> */}
                      <LinkDom
                        to="/login" 
                        className='nav-link'
                      >Minha Conta</LinkDom>
                    {/* </a> */}
                  </li>
                  {/* <li
                    // className="nav-item active"
                  >
                    <Button
                      variant='primary'
                      size='lg'
                      onClick={() => navigate('/login')}
                      className='rounded-5 mx-3 p-2 shadow-sm'
                    >
                      Minha Conta
                    </Button>
                  </li> */}
                </ul>
              </div>
            </nav>
            <nav 
              id="hamburger-nav"
            >
              <div className="hamburger-menu">
                <div className="hamburger-icon" onClick={toggleMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="menu-links">
                  <li>
                      <Link 
                        to="header" 
                        onClick={toggleMenu}
                        smooth={true} 
                        duration={500}
                        className='nav-link'
                      >Home</Link>
                  </li>
                  <li>
                    <Link 
                        to="about" 
                        onClick={toggleMenu}
                        smooth={true} 
                        duration={500}
                        className='nav-link'
                    >Sobre</Link>
                  </li>
                  <li>
                    <Link 
                        to="services" 
                        onClick={toggleMenu}
                        smooth={true} 
                        duration={500}
                        className='nav-link'
                    >Serviços</Link>
                  </li>
                  <li>
                    <Link 
                      to="team" 
                      onClick={toggleMenu}
                      smooth={true} 
                      duration={500}
                      className='nav-link'
                    >Time</Link>
                  </li>
                  <li>
                    <Link 
                      to="pricing" 
                      onClick={toggleMenu}
                      smooth={true} 
                      duration={500}
                      className='nav-link'
                    >Assinatura</Link></li>
                  <li>
                    <Link 
                      to="contact" 
                      onClick={toggleMenu}
                      smooth={true} 
                      duration={500}
                      className='nav-link'
                    >Contato</Link>
                  </li>
                  <li
                    className="nav-item active"
                  >
                    {/* <a href=''> */}
                      <LinkDom
                        to="/login" 
                        className='nav-link'
                      >Minha Conta</LinkDom>
                  </li>
                    {/* </a> */}
                  {/* <li>
                    <Link 
                      onClick={() => navigate('/login')}
                      smooth={true} 
                      duration={500}
                      className='nav-link'
                    >Minha Conta</Link>
                  </li> */}
                </div>
              </div>
            </nav>
          </div>
      </header>
    </Element>
  )
}

export default Header