import React, {useState} from 'react';
import './login.css';
import Footer from '../../components/common/Footer';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import { doLogin } from '../../services/authService';

const Login = () => {
    const navigate = useNavigate();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(() => !showPassword);
    };

    const goToPricing = () => {
        navigate('/');
        setTimeout(() => {
            const element = document.getElementById('pricing');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    }

    const handleLogin = event => {
        event.preventDefault();
        if (doLogin(login, password)) {
            navigate('/client');
        } else {
            setShowModal(true);
        }
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <section id='login' /*style={{height: '700px'}}*/>
            <div
                style={{position: 'absolute'}}
                className='mt-4'
            >
                <Link to="/">
                    <span className='super-font arrow-btn text-black'>&larr;</span>
                </Link>
            </div>
            <div className="container d-flex justify-content-center align-items-start min-vh-100 mx-auto" /*style={{height: '700px'}}*/>
                <div className="col-md-6 right-box wrapper border rounded-3 p-3 shadow box-area text-center" style={{marginTop: '5rem'}}>
                    <div className="row align-items-center py-5 px-2">
                        <form onSubmit={handleLogin}>
                            <div className="header-text mb-4">
                                <img
                                    src={require('../../assets/img/TransparenteSemNome.png')}
                                    style={{width: '50px', height: '50px'}}
                                />
                                <h1 className='super-font'>Olá, Retinaxer!</h1>
                                <p className='super-font-sm text-center'>Estamos muito felizes por ter você aqui!</p>
                            </div>
                            <div className="input-group mb-3">
                                <input 
                                    type="text" 
                                    className="form-control form-control-lg fs-6 rounded-2" 
                                    placeholder="CNPJ ou E-mail"
                                    value={login}
                                    onChange={event => setLogin(event.target.value)}
                                />
                            </div>
                            <div className="row">
                                <div className="col-md-6 w-100">
                                    <div className='input-group mb-3 border rounded-2'>
                                        <input 
                                            type={showPassword ? 'text' : 'password'} 
                                            className="form-control form-control-lg fs-6 border-0" 
                                            placeholder="Senha"
                                            value={password}
                                            onChange={event => setPassword(event.target.value)}
                                        />
                                        <div 
                                            className='d-flex input-group-prepend justify-content-center align-items-center p-1'
                                            onClick={togglePasswordVisibility} 
                                            style={{cursor: 'pointer'}}
                                        >
                                            <span>
                                            {
                                                showPassword ?
                                                <FaEyeSlash size={14}/> 
                                                :
                                                <FaEye size={14}/>
                                            }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-md-6'>
                                    <div className="align-items-center justify-content-center">
                                        <input type="checkbox" className="form-check-input mx-2" id="formCheck" style={{cursor: 'pointer'}}/>
                                        <label htmlFor="formCheck" className="form-check-label"><small className='super-font-sm' style={{cursor: 'pointer'}}>Lembrar-me</small></label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={'my-3'}>
                                        <small><a href="#" className='super-font-sm text-decoration-underline'>Esqueci minha senha</a></small>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <button
                                    type='submit'
                                    className="btn btn-lg btn-primary w-100 fs-6"
                                >Login</button>
                            </div>
                            <div className="row">
                                <small className='super-font-sm'>Ainda não tem uma assinatura? <br/><Link to='/' onClick={goToPricing} className='super-font-sm text-decoration-underline'>Assine agora!</Link></small>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {
                showModal && (
                    <Modal show={true} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Tentativa de Login</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Parece que suas credenciais são inválidas.</p> <br/>
                            <p>Verifique seus dados de acesso e tente novamente!</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Voltar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }
            <Footer showMenuIcons={false}/>
        </section>
    );
}

export default Login;