import React from 'react'
import { FaCheckDouble } from 'react-icons/fa';
import AnimatedSection from '../common/AnimatedSection';

const About = () => {
  return (
    // <section id="about" className='mt-5 show-slowly p-2'>
    <AnimatedSection
      id='about'
    >
      <div className='container-fluid bg-white p-5 shadow' style={{marginTop: '8rem'}}>
        <div className='mb-5 py-2 justify-content-center line-container'>
          <div className="line"></div>
          <h2 className="section-title">Sobre Nós</h2>
          <div className="line"></div>
        </div>
        <div id="about-background" className='row content text-justify'>
          <div className="col-lg-6 text-justify">
            <p className='text-justify lead'>
            <span className="ms-5"/>Somos uma equipe dedicada de profissionais apaixonados por simplificar processos e impulsionar o crescimento empresarial. Nosso foco principal está em atender às necessidades exclusivas dos escritórios de contabilidade, oferecendo serviços especializados de disponibilização de <strong>telefones corporativos</strong> e automação eficiente de mensagens via <strong>WhatsApp</strong>.
            </p>
            <ul className='list-group d-flex'>
              <li className='list-group-item align-items-start lead'>
              <p><FaCheckDouble className="text-success me-2 "/><strong>Expertise no Setor:</strong> Conhecemos as nuances específicas dos escritórios de contabilidade e adaptamos nossos serviços para atender às suas necessidades;
              </p>
              </li>
              <li className='list-group-item align-items-start lead'>
                <p><FaCheckDouble className="text-success me-2" /><strong>Tecnologia Avançada:</strong> Utilizamos as últimas tecnologias para oferecer soluções de comunicação eficientes e de ponta;
                </p>
              </li>
              <li className='list-group-item align-items-start lead'>
              <p>
              <FaCheckDouble className="text-success me-2" /><strong>Suporte Personalizado:</strong> Estamos sempre à disposição para oferecer suporte personalizado e garantir que você aproveite ao máximo nossos serviços.
              </p>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 lead">
            <p>
              <span className="ms-5"/>Estamos empenhados em impulsionar o sucesso dos escritórios de contabilidade por meio da inovação na comunicação. Acreditamos que a eficiência e a personalização são fundamentais, e nossa missão é capacitar sua equipe a alcançar novos patamares na prestação de serviços contábeis.
            </p>
          </div>
        </div>
      </div>
      </AnimatedSection>
  )
}

export default About