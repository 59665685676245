import React from 'react'
import './utils/style.css';
import AnimatedSection from '../common/AnimatedSection';

const Hero = () => {
  return (
      <AnimatedSection 
        id='hero'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center'>
              <h1 className='title cool-font my-5'>Fale com seus clientes com Retinax</h1>
              <h2 className='subtitle mt-1 mb-5 cool-font-2'>Somos especialistas em leads, transformando sua comunicação com seus clientes!</h2>
            </div>
            <div 
              className="col-lg-6 order-1 order-lg-2 hero-img carousel-container my-3"
            >
              <img
                src={require('../../assets/img/retinax-no-name.png')}
                className="img-fluid animated-image"
              />
            </div>
          </div>
        </div>
      </AnimatedSection>
  )
}

export default Hero