import { isEmail } from "validator";

const CNPJ = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
const CNPJ_DIGITS = /^\d{14}$/;

function isValidCNPJ(cnpj) {
    return CNPJ_DIGITS.test(cnpj);
}

function isValidEmail(email) {
    return isEmail(email);
}

export {
    isValidCNPJ,
    isValidEmail
};