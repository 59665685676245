function on(listener, selector, callback) {
    const elements = document.querySelectorAll(selector);
    elements.forEach(element => {
        element.addEventListener(listener, () => callback(element));
    });
}

on('mouseover', '.nav-link', element => {
    element.classList.add('text-primary');
})

on('mouseout', '.nav-link', element => {
    element.classList.remove('text-primary');
})

export function toggleMenu() {
    const menu = document.querySelector(".menu-links");
    const icon = document.querySelector(".hamburger-icon");
    menu.classList.toggle("open");
    icon.classList.toggle("open");
}