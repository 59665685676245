import React from 'react';
import { Link } from 'react-scroll';

const Footer = ({showMenuIcons=true}) => {
  return (
    // <section
      // id={'footer'}
    // >
      <footer id="footer"/*style={{marginBottom: '-13.12rem'}}*/>
        <div className="bg-white py-5 shadow text-center row bg-primary" >
            <div className={`col-lg-6 text-lg-left ${showMenuIcons ? '' : 'w-100 text-center'}`}>
              <div className="text-muted">
                &copy; {new Date().getFullYear()} Retinax. Todos os direitos reservados
              </div>
            </div>
            {
              showMenuIcons ? 
            <div className="col-lg-6">
              <nav className="footer-links text-center pt-2 pt-lg-0 justify-content-between d-flex">
                <Link 
                  to="header" 
                  smooth={true} 
                  duration={500}
                  className='mx-1 text-muted'
                >Home</Link>
                <Link 
                  to="about" 
                  smooth={true} 
                  duration={500}
                  className='mx-1 text-muted'
                  >Sobre</Link>
                <Link 
                  to="services" 
                  smooth={true} 
                  duration={500}
                  className='text-muted'
                  >Serviços</Link>
                <Link 
                  to="team" 
                  smooth={true} 
                  duration={500}
                  className='mx-1 text-muted'
                  >Time</Link>
                <Link 
                  to="pricing" 
                  smooth={true} 
                  duration={500}
                  className='text-muted'
                  >Assinatura</Link>
                <Link 
                  to="contact" 
                  smooth={true} 
                  duration={500}
                  className='mx-1 text-muted'
                >Contato</Link>
              </nav>
            </div>
            : null}
          </div>
      </footer>
    // </section>
  )
}

export default Footer;