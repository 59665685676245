import React from 'react'
import AnimatedSection from '../common/AnimatedSection'
import Retinax from '../common/Retinax';
import { FaInstagram, FaTwitter } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { BiMapPin } from 'react-icons/bi';
import { BiMailSend } from 'react-icons/bi';
import { Button } from 'react-bootstrap';

const Contact = () => {
  return (
    <AnimatedSection
      id={'contact'}
    >
      <div className='container-fluid bg-white p-5 shadow mt-5'>
      <div className='mb-5 py-2 justify-content-center line-container'>
          <div className="line"></div>
          <h2 className="section-title">Contato</h2>
          <div className="line"></div>
        </div>
        <div className='row my-5'>
          <div 
            className='col-lg-4 col-md-6 mb-5 text-center' 
          >
            <h3><Retinax/></h3>
            <h6><strong>Fale com seus clientes!</strong></h6>
            <div>
              <a href='https://www.twitter.com' target='new'>
                <FaTwitter size={24} color='blue'/>
              </a>
              <a href='https://www.facebook.com' target='new' className='mx-2'>
                <FaFacebook size={24} color='blue'/>
              </a>
              <a href='https://www.instagram.com' target='new' className='mx-2'>
                <FaInstagram size={24} color='red'/>
              </a>
              <a href='https://www.linkedin.com' target='new' className='mx-2'>
                <FaLinkedin size={24} color='blue'/>
              </a>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 mb-5'>
            <ul className='navbar-nav mx-auto' style={{ width: '65%'}}>
              <li className='text-center mb-3'>
                <BiMapPin size={30} color="blue" className='text-center mx-3 my-1'/>
                <br/>
                Morrinhos, GO 75650-000
              </li>
              <li className='text-center mb-3'>
                <BiMailSend size={30} color="blue" className='text-center mx-3 my-1'/>
                <br/>
                suporte@retinax.com.br
              </li>
              <li className='text-center'>
                <a href='https://wa.me/+5562981204899' target='new' className='text-dark'> 
                  <FaWhatsapp size={30} color="green" className='text-center mx-3 my-1'/>
                  <br/>
                  (62) 9 8120-4899
                </a>
              </li>
            </ul>
          </div>
          <div
            className="container col-lg-4 col-md-6"
          >
            <h4>Envie-nos um e-mail</h4>
            <form
              action="forms/contact.php"
              method="post"
              role="form"
              className="php-email-form"
            >
              <div className="form-group my-3">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Seu nome"
                  required
                />
              </div>
              <div className="form-group my-3">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Seu e-mail"
                  required
                />
              </div>
              <div className="form-group my-3">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Assunto"
                  required
                />
              </div>
              <div className="form-group my-3">
                <textarea
                  className="form-control"
                  name="message"
                  rows={5}
                  placeholder="Mensagem"
                  required
                  defaultValue={""}
                />
              </div>
              <div className='text-center'>
                <Button 
                  type='submit'
                  onClick={() => window.alert('E-mail enviado com sucesso!')}
                >Enviar</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AnimatedSection>
  )
}

export default Contact;