import React from 'react';
import AnimatedSection from '../common/AnimatedSection';
import { BiQuestionMark } from 'react-icons/bi';
import { RiQuestionLine } from 'react-icons/ri';

const FAQ = () => {
  return (
    <AnimatedSection id={'faq'}>
      <div className='container-fluid bg-white p-5 shadow mt-5'>
      <div className='mb-5 py-2 justify-content-center line-container'>
          <div className="line"></div>
          <h2 className="section-title">Perguntado Frequentemente</h2>
          <div className="line"></div>
        </div>
        <div className='magnify-container row d-flex align-items-center mx-2' style={{marginTop: '7rem'}}>
          <div className="col-lg-5 lead question-container">
            <div className='row'>
              <div className='col-md-2'>
                <RiQuestionLine size={30} color="green" className='mx-5'/>
              </div>
              <div className='col-md-10'>
                <h4>Quais são os benefícios do teste grátis de 7 dias?</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-7 lead">
            <p>
              O teste grátis oferece acesso limitado à plataforma, permitindo que você experimente nossos recursos e avalie como a automação de contatos pode impulsionar sua eficiência antes de tomar uma decisão informada.
            </p>
          </div>
          <hr/>
        </div>
        <div className='magnify-container row faq-item d-flex align-items-center mx-2'>
          <div className="col-lg-5 lead question-container">
            <div className='row'>
              <div className='col-md-2'>
                <RiQuestionLine size={30} color="green" className='mx-5'/>
              </div>
              <div className='col-md-10'>
                <h4>Posso personalizar as regiões dos contatos nos planos Premium e Golden?</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-7 lead">
            <p>
              Sim, nos planos Premium e Golden, você tem a flexibilidade de personalizar até três ou dez regiões, respectivamente, para atender às suas necessidades específicas de fornecimento de contatos. Mas preste atenção, isso é possível apenas no ato da compra!
            </p>
          </div>
          <hr/>
        </div>
        <div className='magnify-container row faq-item d-flex align-items-center mx-2'>
          <div className="col-lg-5 lead question-container">
            <div className='row'>
              <div className='col-md-2'>
                <RiQuestionLine size={30} color="green" className='mx-5'/>
              </div>
              <div className='col-md-10'>
                <h4>Posso estender a licença mensal do Plano Premium para além de um mês?</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-7 lead">
            <p>
              Certamente! Oferecemos opções flexíveis para estender a licença do Plano Premium por vários meses, proporcionando a você a liberdade de escolher a duração que melhor se alinha às suas metas.
            </p>
          </div>
          <hr/>
        </div>
        <div className='magnify-container row faq-item d-flex align-items-center mx-2'>
          <div className="col-lg-5 lead question-container">
            <div className='row'>
              <div className='col-md-2'>
                <RiQuestionLine size={30} color="green" className='mx-5'/>
              </div>
              <div className='col-md-10'>
                <h4>Qual a diferença entre os planos Premium e Golden?</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-7 lead">
            <p>
            Ambos os planos oferecem acesso a regiões personalizáveis, mas o Plano Golden vai além, fornecendo acesso a dez regiões para maximizar sua estratégia de contatos.
            </p>
          </div>
          <hr/>
        </div>
        <div className='magnify-container row faq-item d-flex align-items-center mx-2'>
          <div className="col-lg-5 lead question-container">
            <div className='row'>
              <div className='col-md-2'>
                <RiQuestionLine size={30} color="green" className='mx-5'/>
              </div>
              <div className='col-md-10'>
                <h4>É possível comprar regiões separadamente?</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-7 lead">
            <p>
            Sim, oferecemos a opção de adquirir regiões individualmente para atender às suas demandas específicas. No entanto, observe que escolher um plano completo pode ser mais econômico, proporcionando uma solução abrangente para suas necessidades de comunicação empresarial.
            </p>
          </div>
          <hr/>
        </div>
      </div>
    </AnimatedSection>
  )
}

export default FAQ