import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Buy from './pages/Buy';
import Login from './pages/login/Login';
import SignUp from './pages/SignUp';
import Client from './pages/client/Client';
import Admin from './pages/Admin';
import PrivateRoutes from './util/PrivateRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/styles/general.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/comprar' element={<Buy/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/signup' element={<SignUp/>}/>
          <Route element={<PrivateRoutes/>}>
            <Route path='/client' element={<Client/>} exact/>
            <Route path='/admin' element={<Admin/>} exact/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>  
  );
}

export default App;
