import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const AnimatedSection = ({ children, id }) => {
  const [ref, inView] = useInView();

  const fadeIn = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
  });

  return (
    <animated.div 
        ref={ref} 
        style={fadeIn} 
        className="animated-section d-flex align-items-center"
        id={id}
    >
      {children}
    </animated.div>
  );
};

export default AnimatedSection;
