import React from 'react';
import './utils/style.css';

const Retinax = ({marginTop}) => {
  return (
    <span className="gradient-text" style={marginTop ? {marginTop: marginTop} : {}}>
      Retinax
    </span>
  )
}

export default Retinax