import React, {useState, useRef} from 'react';
import './login/login.css';
import { Link } from 'react-router-dom';
import Footer from '../components/common/Footer';
import Dropdown from 'react-bootstrap/Dropdown';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignUp = () => {
    const smallScreen = window.innerWidth < 768;
    const [ramoAtividade, setRamoAtividade] = useState('Ramo de Atividade');
    const [phoneNumber, setPhoneNumber] = useState('');
    const ramoAtividadeRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showRetype, setShowRetype] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(() => !showPassword);
    };

    const toggleRetypeVisibility = () => {
        setShowRetype(() => !showRetype);
    };

    const handleOnChange = (value, country, e, formattedValue) => {
        setPhoneNumber(value);
    };

  return (
    <section id='signup' style={{height: '1000px'}}>
        <div
            style={{position: 'absolute'}}
            className='mt-4'
        >
            <Link to="/">
                <span className='super-font arrow-btn text-black'>&larr;</span>
            </Link>
        </div>
        <div className="container d-flex justify-content-center align-items-center min-vh-100 mx-auto" style={{height: '1000px'}}>
            <div className="col-md-6 right-box wrapper border rounded-3 p-3 shadow box-area text-center">
                <div className="align-items-center py-5 px-2">
                    <div className="header-text mb-4">
                        <img
                            src={require('../assets/img/TransparenteSemNome.png')}
                            style={{width: '50px', height: '50px'}}
                        />
                        <h1 className='super-font'>Crie sua Conta</h1>
                    </div>
                    <form>
                        <div className={'mb-3'}>
                            <input type="text" className="form-control form-control-lg fs-6 rounded-2" placeholder="Nome da Empresa"/>
                        </div>
                        <Dropdown className={'mb-3 rounded-2 border'} >
                            <Dropdown.Toggle 
                                variant="white" 
                                id="dropdown-basic" 
                                className='text-muted w-100' 
                                style={{textAlign: 'left', display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}
                            >
                                <span style={{flex: 1}}>{ramoAtividade}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu  className='text-muted w-100'>
                                <Dropdown.Item onClick={() => setRamoAtividade('Contabilidade Geral')}>Contabilidade Geral</Dropdown.Item>
                                <Dropdown.Item onClick={() => setRamoAtividade('Consultoria Fiscal')}>Consultoria Fiscal</Dropdown.Item>
                                <Dropdown.Item onClick={() => setRamoAtividade('Gestão Financeira')}>Gestão Financeira</Dropdown.Item>
                                <Dropdown.Item onClick={() => setRamoAtividade('Recursos Humanos')}>Recursos Humanos</Dropdown.Item>
                                <Dropdown.Item onClick={() => setRamoAtividade('Auditoria')}>Auditoria</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={() => setRamoAtividade('Outro')}>Outro</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {
                        ramoAtividade === 'Outro' ? 
                            <div className="mb-3">
                                <input type="text" className="form-control form-control-lg fs-6 rounded-2" placeholder="Ramo de Atividade"/>
                            </div>
                        : null
                        }
                        <div className={'mb-3'}>
                            <PhoneInput
                                country={'br'}
                                value={phoneNumber}
                                onChange={handleOnChange}
                                inputClass='w-100'
                                placeholder='Celular com WhatsApp'
                            />
                        </div>
                        <div className='mb-3 gradient-container-thin rounded-3'/>
                        <div className={'mb-3'}>
                            <input type="text" className="form-control form-control-lg fs-6 rounded-2" placeholder="CNPJ"/>
                        </div>
                        <div className={'mb-3'}>
                            <input type="text" className="form-control form-control-lg fs-6 rounded-2" placeholder="E-mail"/>
                        </div>
                        <div className="mb-3" >
                            <div className='input-group mb-3 border rounded-2'>
                                <input 
                                    type={showPassword ? 'text' : 'password'} 
                                    className="form-control form-control-lg fs-6 border-0" 
                                    placeholder="Senha"
                                    
                                />
                                <div 
                                    className='d-flex input-group-prepend justify-content-center align-items-center p-1'
                                    onClick={togglePasswordVisibility} 
                                    style={{cursor: 'pointer'}}
                                >
                                    <span>
                                    {
                                        showPassword ?
                                        <FaEyeSlash size={14}/> 
                                        :
                                        <FaEye size={14}/>
                                    }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className='input-group mb-3 border rounded-2'>
                                <input 
                                    type={showRetype ? 'text' : 'password'} 
                                    className="form-control form-control-lg fs-6 border-0" 
                                    placeholder="Repita"
                                    
                                />
                                <div 
                                    className='d-flex input-group-prepend justify-content-center align-items-center p-1'
                                    onClick={toggleRetypeVisibility} 
                                    style={{cursor: 'pointer'}}
                                >
                                    <span>
                                    {
                                        showRetype ?
                                        <FaEyeSlash size={14}/> 
                                        :
                                        <FaEye size={14}/>
                                    }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="input-group my-4">
                            <button className="btn btn-lg btn-primary w-100 fs-6">Criar</button>
                        </div>
                        <div className="row">
                            <small className='super-font-sm'>Já possui licença? <Link to='/login' className='super-font-sm text-decoration-underline'>Faça login!</Link></small>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Footer showMenuIcons={false}/>
    </section>
  );
}

export default SignUp;