import React from 'react';
import ContactTable from './components/ContactTable';

const CContacts = () => {
  const data = [
    { empresa: 'Empresa 1', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 2', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 3', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 4', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 5', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 6', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 7', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 8', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 9', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 10', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 11', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 12', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 13', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 14', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 15', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 16', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 17', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 18', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 19', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
    { empresa: 'Empresa 20', regiao: 'Morrinhos-GO', telefone: '+5564992517702' },
  ];

  return <ContactTable data={data}/>;
}

export default CContacts;