import React from 'react';
import AnimatedSection from '../common/AnimatedSection';
import { Button } from 'react-bootstrap';
import { FaThumbsUp } from 'react-icons/fa';
import { FaMedal } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Pricing = () => {
  const navigate = useNavigate();

  return (
    <AnimatedSection
      id={'pricing'}
    >
      <div
        className='container-fluid bg-white p-5 shadow mt-5'
      >
        <div className='mb-5 py-2 justify-content-center line-container'>
          <div className="line"></div>
          <h2 className="section-title">Assinatura</h2>
          <div className="line"></div>
        </div>
        <div style={{marginTop: '7rem'}}>
          <p className='lead text-justify'><span className="ms-5"/>Desbloqueie o potencial máximo da sua comunicação empresarial com nossos planos flexíveis. Experimente gratuitamente por 7 dias e descubra como podemos impulsionar a eficiência da sua estratégia de contatos.</p>
        </div>
        <div
          className='row d-flex justify-content-center mx-1'
        >
          <div
            className='card magnify-container col-md-6 col-lg-3 d-flex align-items-center mb-5 mt-5 mb-lg-0 mx-5 py-5'
          >
            <FaThumbsUp size={30} color="green" className='mb-5 text-center'/>
            <h3 className='mb-5'><strong>Teste Grátis (7 dias)</strong></h3>
            <h1>
              <strong><sup>R$</sup>0</strong>
            </h1>
            <p className='lead my-5 mx-1'><span className='mx-3'/>Explore a plataforma com recursos limitados, ideal para avaliar nossa solução antes de decidir. Teste e descubra o poder da automação de contatos.</p>
            <Button 
              variant={'success'} 
              className="rounded-pill px-4 py-2 w-50"
              onClick={() => navigate('/signup')}
            >
              Testar
            </Button>
          </div>
          <div className='card magnify-container col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mt-5 mb-lg-0 mx-5 py-5 text-center'>
            <div>
              <FaMedal size={30} color="silver" className='mb-5'/>
              <h3 className='mb-5'><strong>Plano Premium</strong></h3>
              <h1>
                <strong><sup>R$</sup>129,90<span> / mês</span></strong>
              </h1>
              <p className='lead my-5 mx-1'><span className='mx-3'/>Desfrute do acesso à plataforma com contatos de três regiões. Escolha a licença mensal ou estenda o benefício por vários meses, conforme suas necessidades.</p>
              <Button 
                variant={'success'} 
                className="rounded-pill px-4 py-2 w-60 mt-3"
                // onClick={(}
              >
                Assinar Agora
              </Button>
            </div>
          </div>
          <div className="card magnify-container col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mt-5 mb-lg-0 mx-5 py-5 text-center">
            <div className=''>
              <FaMedal size={30} color="gold" className='mb-5'/>
              <h3 className='mb-5'><strong>Plano Golden</strong></h3>
              <h1>
                <strong><sup>R$</sup>529,90<span> / mês</span></strong>
              </h1>
              <p className='lead my-5 mx-1'><span className='mx-3'/>Eleve sua experiência com o Plano Golden, oferecendo os mesmos benefícios do Premium, mas com acesso total a 10 regiões para otimizar ainda mais sua estratégia de contatos.</p>
              <Button 
                variant={'success'} 
                className="rounded-pill px-4 py-2 w-60"
                // onClick={(}
              >
                Assinar agora
              </Button>
            </div> 
          </div>
          </div>
      </div>
    </AnimatedSection>
  )
}

export default Pricing