import React from 'react';
import Header from '../components/common/Header';
import Hero from '../components/home/Hero';
import Main from '../components/home/Main';
import Footer from '../components/common/Footer';

const Home = () => {
  return (
    <>
      <Header/>
      <Hero/>
      <Main/>
      <Footer/>
    </>
  )
}

export default Home 