import React from 'react';
import './contactTable.css';
import { Card, Table, Container } from 'react-bootstrap';
import { FaWhatsapp } from 'react-icons/fa';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const ContactTable = ({ data }) => {
  return (
    <Container className='custom-scrollbar'>
      <Table hover style={{ minWidth: '100%' }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Empresa</th>
            <th>Região</th>
            <th>Telefone</th>
            <th>Mensagem</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.empresa}</td>
              <td>{item.regiao}</td>
              <td>{parsePhoneNumberFromString(item.telefone).formatInternational()}</td>
              <td>
                <Card.Link target='new' href={`https://web.whatsapp.com/send/?phone=${item.telefone}`}>
                  <FaWhatsapp size={'2rem'} color={'green'} className='mx-3'/>
                </Card.Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ContactTable;
