import React from 'react';
import About from './About';
import Services from './Services';
import Team from './Team';
import Pricing from './Pricing';
import FAQ from './FAQ';
import Contact from './Contact';

const Main = () => {
  return (
    <main 
      id="main" 
      className='gradient-container' 
      style={{paddingRight: 0, paddingLeft: 0}}
    >
        <About/>
        <Services/>
        <Team/>
        <Pricing/>
        <FAQ/>
        <Contact/>
    </main>
  )
}

export default Main