import { isValidCNPJ, isValidEmail } from '../util/Validator';

var IS_AUTHENTICATED = false;

function doLogin(login, password) {
    if (
        isValidCNPJ(login) &&
        login == '11111111111111' && password == '11111111' ||
        isValidEmail(login) &&
        login == 'suporte@retinax.com.br' && password == '11111111'
    ) {
        IS_AUTHENTICATED = true;
        return true;
    }
    IS_AUTHENTICATED = false;
    return false;
}

const isAuthenticated = () => {
    return IS_AUTHENTICATED;
};

const doLogout = () => {
    IS_AUTHENTICATED = false;
};

export { isAuthenticated, doLogin, doLogout };