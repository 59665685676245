import React, {useState, useEffect, useRef} from 'react';
import Retinax from '../../components/common/Retinax';
import { MdMenu } from 'react-icons/md';
import { Navbar, Nav } from 'react-bootstrap';
import './client.css';
import { FaUser, FaWhatsapp, FaPhone, FaCog, FaInfoCircle, FaChartBar } from 'react-icons/fa';
import CDashboard from './CDashboard';
import CContacts from './contacts/CContacts';
import CAutomation from './CAutomation';
import CProfile from './CProfile';
import CConfig from './CConfig';
import CAbout from './CAbout';
import {Button} from 'react-bootstrap';
import { doLogout } from '../../services/authService';
import { useNavigate } from 'react-router-dom';

const CHeader = ({mainElement, setMainElement}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const drawerRef = useRef(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowDrawer(!showDrawer);
  };

  const relat = {
    'Dashboard': <CDashboard/>,
    'Contatos': <CContacts/>,
    // 'Automação': <CAutomation/>,
    'Perfil': <CProfile/>,
    'Configurações': <CConfig/>,
    'Sobre': <CAbout/>
  };

  const getMenuItem = (itemText) => {
    if (relat[itemText].type == mainElement.type) {
      return <span className='lead text-primary'>{itemText}</span>
    }
    return itemText;
  }

  const handleLogout = () => {
    doLogout();
    navigate('/');
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setShowDrawer(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <header className="bg-white text-black p-3 fixed-top">
      <div className="container-fluid">
        <div className="row d-flex justify-content-between justify-content-md-end align-items-center">
          <div className="col-md-4 d-flex align-items-center">
            <MdMenu 
              size={40}
              color='black' 
              style={{cursor: 'pointer', marginRight: '2rem'}}
              onClick={() => {
                setShowLogoutDialog(false);
                toggleMenu();
              }}
            />
            <img
              src={require('../../assets/img/TransparenteSemNome.png')}
              style={{width: '50px', height: '50px'}}
            />
            <div className='d-flex w-100 text-right d-lg-none justify-content-end align-items-center'>
              <img 
                src={require('../../assets/img/predio.jpg')} 
                alt="Profile" 
                className="rounded-circle shadow"
                style={{ width: '40px', height: '40px', cursor: 'pointer'}}
                onClick={() => setShowLogoutDialog(() => !showLogoutDialog)}
              />
              <div 
              className={`border d-flex justify-content-center align-items-center text-center rounded-3 bg-white shadow ${showLogoutDialog ? 'd-block' : 'd-none'}`}
              style={{
                position: 'absolute',
                left: '3vh',
                top: '10vh',
                height: '50vh',
                width: '40vh',
                zIndex: 999,
                flexDirection: 'column'
              }}
            >
              <div className='row gradient gradient justify-content-center align-items-center w-100 border-bottom' style={{display: 'flex', flex: 1}}>
                <div className='col-md-12 lead'>
                  11.111.111/1111-11
                </div>
              </div>
              <div className='row justify-content-center align-items-center w-100 mt-3' style={{display: 'flex', flex: 1}}>
                <div className='col-md-12'>
                  <img 
                    src={require('../../assets/img/predio.jpg')} 
                    alt="Profile" 
                    className="rounded-circle shadow"
                    style={{ width: '100px', height: '100px', cursor: 'pointer'}}
                    onClick={() => {
                        setShowLogoutDialog(() => !showLogoutDialog);
                        setMainElement(<CProfile/>);
                      }
                    }
                  />
                </div>
              </div>
              <div className='row justify-content-center align-items-center w-100 my-1 lead' style={{display: 'flex', flex: 1}}>
                Olá, <span style={{fontWeight: 'bold', fontSize: '1.5rem', fontFamily: 'Dancing Script, cursive'}}>União Contabilidade!</span> 
              </div>
              <div className='row gradient justify-content-center align-items-center w-100 border-top' style={{display: 'flex', flex: 1, marginTop: 0}}>
                <div className='col-md-12'>
                  <Button variant="danger" onClick={handleLogout}>Sair da Conta</Button>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="col-md-4 d-none d-lg-block">
            <form className="form-inline">
              <input className="form-control mr-sm-2" type="search" placeholder="Pesquisar" aria-label="Search" />
            </form>
          </div>
          <div className="col-md-4 d-flex justify-content-end align-items-center d-none d-lg-block" style={{textAlign: 'right'}}>
            <img 
              src={require('../../assets/img/predio.jpg')} 
              alt="Profile" 
              className="rounded-circle shadow"
              style={{ width: '40px', height: '40px', cursor: 'pointer'}}
              onClick={() => setShowLogoutDialog(() => !showLogoutDialog)}
            />
            <div 
              className={`border d-flex justify-content-center align-items-center text-center rounded-3 bg-white shadow ${showLogoutDialog ? 'd-block' : 'd-none'}`}
              style={{
                position: 'absolute',
                left: '160vh',
                top: 80,
                height: '50vh',
                width: '50vh',
                zIndex: 999,
                flexDirection: 'column'
              }}
            >
              <div className='row gradient justify-content-center align-items-center w-100 border-bottom' style={{display: 'flex', flex: 1}}>
                <div className='col-md-12 lead'>
                  11.111.111/1111-11
                </div>
              </div>
              <div className='row justify-content-center align-items-center w-100 mt-3' style={{display: 'flex', flex: 1}}>
                <div className='col-md-12'>
                  <img 
                    src={require('../../assets/img/predio.jpg')} 
                    alt="Profile" 
                    className="rounded-circle shadow"
                    style={{ width: '100px', height: '100px', cursor: 'pointer'}}
                    onClick={() => {
                        setShowLogoutDialog(() => !showLogoutDialog);
                        setMainElement(<CProfile/>);
                      }
                    }
                  />
                </div>
              </div>
              <div className='row justify-content-center align-items-center w-100 my-1 lead' style={{display: 'flex', flex: 1}}>
                Olá, <span style={{fontWeight: 'bold', fontSize: '1.5rem', fontFamily: 'Dancing Script, cursive'}}>União Contabilidade!</span> 
              </div>
              <div className='row gradient justify-content-center align-items-center w-100 border-top' style={{display: 'flex', flex: 1, marginTop: 0}}>
                <div className='col-md-12'>
                  <Button variant="danger" onClick={handleLogout}>Sair da Conta</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navbar
        className={`bg-white shadow ${showDrawer ? 'd-block' : 'd-none'}`}
        style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh'}}
      >
        <Navbar.Collapse id="navbar-drawer">
          <Nav 
            className="flex-column text-right"
            style={{width: '100%', height: '100%'}}
            >
            <div className='justify-content-start align-items-center' style={{display: 'inline-flex'}}> 
              <div className='col-md-1'>
                <MdMenu
                  aria-controls='navbar-toggle'
                  size={40}
                  color='black' 
                  style={{cursor: 'pointer', marginLeft: '1.75rem', marginTop: '.8rem', marginRight: '2rem'}}
                  onClick={toggleMenu}
                />
              </div>
              <div className='col-md-11'>
                <Retinax/>
              </div>
            </div>
            <hr/>
            <Nav.Link 
              href="#dashboard"
              onClick={() => {
                setMainElement(<CDashboard/>);
                setShowDrawer(false);
              }}
              className='rounded-3 mx-3 my-1 p-3 dashboard-amazing' 
            >
              <FaChartBar size={'2rem'} color={'orange'} className='mx-3'/>{getMenuItem('Dashboard')}
            </Nav.Link>
            <Nav.Link 
              href="#contatos"
              onClick={() => {
                setMainElement(<CContacts/>);
                setShowDrawer(false);
              }}
              className='rounded-3 mx-3 my-1 p-3 dashboard-amazing' 
            >
              <FaWhatsapp size={'2rem'} color={'green'} className='mx-3'/>{getMenuItem('Contatos')}
              {/* <FaPhone size={'2rem'} color={'#000080'} className='mx-3'/>{getMenuItem('Contatos')} */}
            </Nav.Link>
            {/* <Nav.Link 
              href="#automacao"
              onClick={() => {
                setMainElement(<CAutomation/>);
                setShowDrawer(false);
              }}
              className='rounded-3 mx-3 my-1 p-3 dashboard-amazing' 
              ><FaWhatsapp size={'2rem'} color={'green'} className='mx-3'/>{getMenuItem('Automação')}</Nav.Link>
            */}
            <hr/>
            <Nav.Link 
              href="#perfil"
              onClick={() => {
                setMainElement(<CProfile/>);
                setShowDrawer(false);
              }}
              className='rounded-3 mx-3 my-1 p-3 dashboard-amazing' 
            >
              <FaUser size={'2rem'} color={'blue'} className='mx-3'/>{getMenuItem('Perfil')}
            </Nav.Link>
          <Nav.Link 
            href="#configuracoes"
            onClick={() => {
              setMainElement(<CConfig/>);
              setShowDrawer(false);
            }}
            className='rounded-3 mx-3 my-1 p-3 dashboard-amazing' 
            ><FaCog size={'2rem'} color={'grey'} className='mx-3'/>{getMenuItem('Configurações')}</Nav.Link>
          <Nav.Link 
            href="#sobre"
            onClick={() => {
              setMainElement(<CAbout/>);
              setShowDrawer(false);
            }}
            className='rounded-3 mx-3 my-1 p-3 dashboard-amazing' 
            ><FaInfoCircle size={'2rem'} color={'grey'} className='mx-3'/>{getMenuItem('Sobre')}</Nav.Link>
            <div 
              className='d-flex fixed-bottom p-1 justify-content-center'
              style={{fontSize: '.8em', textAlign: 'center'}}
            >
              <Nav.Link
              >Política de Privacidade</Nav.Link>
              <Nav.Link
              >Termos de Serviço</Nav.Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default CHeader;
