import React, { useState } from 'react'
import AnimatedSection from '../common/AnimatedSection'
import { Card } from 'react-bootstrap';
import { FaInstagram } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';

const Team = () => {
  const [instIconColor1, setInstIconColor1] = useState('red');
  const [instIconColor2, setInstIconColor2] = useState('red');
  const [instIconColor3, setInstIconColor3] = useState('red');
  const [instIconColor4, setInstIconColor4] = useState('red');
  
  const [fbIconColor1, setFbIconColor1] = useState('blue');
  const [fbIconColor2, setFbIconColor2] = useState('blue');
  const [fbIconColor3, setFbIconColor3] = useState('blue');
  const [fbIconColor4, setFbIconColor4] = useState('blue');

  const [liIconColor4, setLiIconColor4] = useState('blue');

  return (
    <AnimatedSection
      id={'team'}
    >
      <div
        className='container-fluid bg-white p-5 shadow mt-5'
      >
        <div className='mb-5 py-2 justify-content-center line-container'>
          <div className="line"></div>
          <h2 className="section-title">Time</h2>
          <div className="line"></div>
        </div>
        <div style={{marginTop: '7rem'}}>
          <p className='lead text-justify'><span className="ms-5"/>Contamos com especialistas em software de comunicação empresarial, profissionais experientes em mercado e vendas, acumulando mais de 30 anos de experiência. Além disso, nossa equipe inclui contadores especializados, garantindo uma abordagem completa e integrada para atender às necessidades específicas do seu negócio.</p>
        </div>
        <div className='row d-flex justify-content-center mx-1 text-center'>
          <Card 
            style={{width: '18rem'}} 
            className='p-0 mx-5 my-5 with-hover'
            onMouseOver={() => {
              setInstIconColor1('gold');
              setFbIconColor1('gold');
            }}
            onMouseOut={() => {
              setInstIconColor1('red');
              setFbIconColor1('blue');
            }}
          >
            <Card.Img variant='top' className='w-100 h-100' src={require('../../assets/img/team/welton.jpg')}/>
            <Card.Body>
              <Card.Title>Welton Toledo</Card.Title>
              <Card.Text className='text-center'>Gestor Empresarial com +30 anos de experiência</Card.Text>
              <a href='https://www.instagram.com/toledo.ms/' target='new' className='mx-2'>
                <FaInstagram color={instIconColor1} size={24}/>
              </a>
              <a href='https://www.facebook.com/people/Welton-Toledo/pfbid02CB8R77HfNTGot4TdoSxBrgPd5tDjcPngfmNxp5LphPkwEaj6DubfnNGydvSXfCuql/' target='new' className='mx-2'>
              <FaFacebook color={fbIconColor1} size={24}/>
              </a>
            </Card.Body>
          </Card>
          <Card 
            style={{width: '18rem'}} 
            className='p-0 mx-5 my-5 with-hover'
            onMouseOver={() => {
              setInstIconColor3('gold');
              setFbIconColor3('gold');
            }}
            onMouseOut={() => {
              setInstIconColor3('red');
              setFbIconColor3('blue');
            }}
          >
            <Card.Img variant='top' className='w-100 h-100' src={require('../../assets/img/team/murilo.png')}/>
            <Card.Body>
              <Card.Title>Murilo Alves</Card.Title>
              <Card.Text className='text-center'>Especialista em Marketing Digital</Card.Text>
              <a href='https://www.instagram.com/murilosilvaalvespv' target='new' className='mx-2'>
                <FaInstagram color={instIconColor3} size={24}/>
              </a>
              {/* <a href='https://www.facebook.com/people/Welton-Toledo/pfbid02CB8R77HfNTGot4TdoSxBrgPd5tDjcPngfmNxp5LphPkwEaj6DubfnNGydvSXfCuql/' target='new' className='mx-2'>
              <FaFacebook color={fbIconColor1} size={24}/>
              </a> */}
            </Card.Body>
          </Card>
          <Card
            style={{width: '18rem'}} 
            className='p-0 mx-5 my-5 with-hover'
            onMouseOver={() => {
              setInstIconColor2('gold');
              setFbIconColor2('gold');
            }}
            onMouseOut={() => {
              setInstIconColor2('red');
              setFbIconColor2('blue');
            }}
          >
            <Card.Img variant='top' className='w-100 h-100' src={require('../../assets/img/team/brian.jpeg')}/>
            <Card.Body>
              <Card.Title>Brian Queiroz</Card.Title>
              <Card.Text className='text-center'>Bacharel em Ciência da Computação</Card.Text>
              <a href='https://www.instagram.com/brian_.queiroz/' target='new' className='mx-2'>
                <FaInstagram color={instIconColor2} size={24}/>
              </a>
              <a href='https://m.facebook.com/Brian.Queiroz.1/' target='new' className='mx-2'>
              <FaFacebook color={fbIconColor2} size={24}/>
              </a>
            </Card.Body>
          </Card>
          <Card 
            style={{width: '18rem'}} 
            className='p-0 mx-5 my-5 with-hover'
            onMouseOver={() => {
              setInstIconColor4('gold');
              setFbIconColor4('gold');
              setLiIconColor4('gold');
            }}
            onMouseOut={() => {
              setInstIconColor4('red');
              setFbIconColor4('blue');
              setLiIconColor4('blue');
            }}
          >
            <Card.Img variant='top' className='w-100 h-100' src={require('../../assets/img/team/rafael.jpg')}/>
            <Card.Body>
              <Card.Title>Rafael Guimarães</Card.Title>
              <Card.Text className='text-center'>Bacharel em Ciência da Computação</Card.Text>
              <a href='https://www.instagram.com/rafaelfonseca7074' target='new' className='mx-2'>
                <FaInstagram color={instIconColor4} size={24}/>
              </a>
              <a href='https://www.linkedin.com/in/rafael-guimar%C3%A3es-9a8b251a9/' target='new' className='mx-2'>
              <FaLinkedin color={liIconColor4} size={24}/>
              </a>
            </Card.Body>
          </Card>
        </div>
      </div>
    </AnimatedSection>
  )
}

export default Team