import React, {useState} from 'react';
import CHeader from './CHeader';
import CDashboard from './CDashboard';

const Client = () => {
  const [mainElement, setMainElement] = useState(<CDashboard/>);
  return (
    <div 
      className='container-fluid d-flex align-items-center justify-content-center'
      style={{height: '100vh'}}
    >
      <div className='row'>
        <div className='col-md-12'>
          <CHeader mainElement={mainElement} setMainElement={setMainElement}/>
        </div>
      </div>
      <div className='row w-100' style={{position: 'relative', zIndex: 998}}>
        <div className='col-md-12'>
          {mainElement}
        </div>
      </div>
    </div>
  )
}

export default Client;