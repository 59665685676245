import React, { useState } from 'react';
import AnimatedSection from '../common/AnimatedSection';
import { FaFile } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';

const Services = () => {
  const [fileIconColor, setFileIconColor] = useState('orange');
  const [wppIconColor, setWppIconColor] = useState('green');

  return (
    <AnimatedSection
      id='services'
    >
      <div
        className='container-fluid bg-white p-5 shadow mt-5'
      >
        <div className='mb-5 py-2 justify-content-center line-container'>
          <div className="line"></div>
          <h2 className="section-title">Serviços</h2>
          <div className="line"></div>
        </div>
        <div className='lead' style={{marginTop: '7rem'}}>
          <p className='lead text-justify'><span className="ms-5"/>Somos especialistas em fornecer soluções de vanguarda para otimizar sua comunicação e fortalecer seus relacionamentos.</p>
        </div>
        <div
          className='row d-flex justify-content-center mx-1'
        >
          <div 
            className="card with-hover col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mt-5 mb-lg-0 mx-5 py-5"
            onMouseOver={() => setFileIconColor('white')}
            onMouseOut={() => setFileIconColor('orange')}
          >
            <div>
              <div className='mb-3 text-center'>
                <FaFile color={fileIconColor} size={24}/>
              </div>
              <h4 className='mb-4 card-title text-center'>
                Telefones Corporativos
              </h4>
              <p className='text-center card-body lead'>
                Transforme a comunicação empresarial com nossa API dedicada para telefones corporativos, oferecendo integração fácil, personalização flexível e suporte especializado para escritórios de contabilidade.
              </p>
            </div>
          </div>
          <div 
            className="card with-hover col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mt-5 mb-lg-0 mx-5 py-5"
            onMouseOver={() => setWppIconColor('white')}
            onMouseOut={() => setWppIconColor('green')}
          >
            <div>
              <div className='mb-3 text-center'>
                <FaWhatsapp id='wpp-icon' color={wppIconColor} size={24}/>
              </div>
              <h4 className='mb-4 card-title text-center'>
                Automação de WhatsApp
              </h4>
              <p className='text-center card-body lead'>
              Transforme a comunicação empresarial com nossa plataforma líder em automação de WhatsApp, oferecendo um disparador de mensagens consolidado, eficiente, personalizado e com suporte especializado para impulsionar a interação com clientes em escritórios de contabilidade.
              </p>
            </div>
          </div>
        </div>
      </div>
    </AnimatedSection>
  )
}

export default Services